import request from '@/utils/request'

// 上传图片

export function uploadImg(params) {
  return request({
    path:'webapi/uploadService/uploadWxImg',
    method: 'post',
    data: params
  })
}

export function getImage(data) {
  return request({
    path: 'webapi/attachmentService/getImage',
    responseType: 'arraybuffer',
    isLoading: false,
    method: 'get',
    data
  })
}

export function getFile(data) {
  return request({
    path: 'webapi/attachmentService/getFile',
    responseType: 'arraybuffer',
    isLoading: false,
    method: 'get',
    data
  })
}

// 获取jssdk配置
export function getJsApiConfig(data) {
  return request({
    method: 'post',
    path: 'common/getJsApiConfig',
    data
  })
}

// 获取微信分享内容
export function getShareConfig(data) {
  return request({
    method: 'post',
    path: 'common/getShareConfig',
    data
  })
}