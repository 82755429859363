<template>
  <div>
    <Header title="工单处理" color="#666" />
    <van-cell-group>
      <van-form
        input-align="right"
        error-message-align="right"
        class="form"
        @submit="onSubmit"
      >
        <van-field
          v-model="form.orderName"
          readonly
          name="orderName"
          label="工单名称"
          placeholder="请填写工单名称"
          clearable
          :rules="[{ required: true }]"
        />

        <!-- <van-field
          clickable
          readonly
          v-model="form.deviceName"
          name="deviceName"
          label="监控设备"
          placeholder="选择监控设备"
          is-link
          @click="showDevicePicker = true"
          :rules="[{ required: true }]"
        /> -->

        <van-field
          readonly
          clickable
          v-model="form.disposeTime"
          name="disposeTime"
          label="处理时间"
          placeholder="请选择处理时间"
          is-link
          :rules="[{ required: true }]"
        />

        <FieldLabel name="问题及处理结果" />
        <van-field
          type="textarea"
          v-model="form.disposeResult"
          name="disposeResult"
          autosize
          rows="3"
          maxlength="500"
          show-word-limit
          placeholder="请填写处理结果"
          clearable
          label=""
          label-width="0"
          input-align="left"
          error-message-align="left"
          :rules="[{ required: true }]"
        />

        <FieldLabel name="处理前的图片" />
        <van-field
          name="files"
          label=""
          label-width="0"
          input-align="left"
          error-message-align="left"
          v-model="imglist[0]"
         :rules="[{ required: true, message: '请上传处理前的图片' }]"
        >
          <template #input>
            <div class="photo">
              <img
                v-for="(item, index) in imglist"
                @click="showImagePreview(imglist, index)"
                :key="item"
                :src="item"
                alt=""
              />
              <!-- <img :src="hecun" @click="takePhoto(1)" alt="" /> -->
              <div @click="takePhoto(1)" class="gray">
                <van-icon class="picture" name="photograph" />
              </div>
            </div>
          </template>
        </van-field>
        <FieldLabel name="处理后的图片" />
        <van-field
          name="files"
          label=""
          label-width="0"
          input-align="left"
          error-message-align="left"
          v-model="afterimglist[0]"
         :rules="[{ required: true, message: '请上传处理后的图片' }]"
        >
          <template #input>
            <div class="photo">
              <img
                v-for="(item, index) in afterimglist"
                @click="showImagePreview(afterimglist, index)"
                :key="item"
                :src="item"
                alt=""
              />
              <!-- <img :src="hecun" @click="takePhoto(1)" alt="" /> -->
              <div @click="takePhoto(2)" class="gray">
                <van-icon class="picture" name="photograph" />
              </div>
            </div>
          </template>
        </van-field>
        <template v-if="recordList.length == 0">
          <van-button
            size="large"
            class="btn"
            color="#08a9f7"
            :loading="loading"
            loading-text="提交中..."
            native-type="submit"
            >开始处理</van-button
          >
        </template>
        <template v-else>
          <van-button
            size="large"
            class="btn"
            color="#08a9f7"
            :loading="loading"
            loading-text="提交中..."
            native-type="submit"
            >提交</van-button
          >
        </template>
      </van-form>
    </van-cell-group>
    <!-- 设备选择 -->
    <van-popup v-model="showDevicePicker" position="bottom">
      <van-picker
        title="选择监控设备"
        show-toolbar
        value-key="name"
        :columns="deviceList"
        @confirm="selectDevice"
        @cancel="showDevicePicker = false"
      />
    </van-popup>
    <!-- 时间选择 -->
    <van-popup v-model="showTimePicker" position="bottom">
      <van-datetime-picker
        v-model="datetime"
        type="datetime"
        title="选择时间"
        :formatter="formatter"
        @cancel="showTimePicker = false"
        @confirm="onTimeConfirm"
      />
    </van-popup>
    <!-- 图片预览 -->
    <!-- <van-image-preview v-model="imgshow" :images="imglist" >
</van-image-preview>
    <van-image-preview v-model="afterimgshow" :images="afterimglist" >
</van-image-preview> -->
  </div>
</template>
<script>
import {
  getGongdanDetail,
  getGongdanRecordList,
  saveGongdanRecord,
} from "@/api/gongdan";
import { getDeviceList } from "@/api/device";
import { getConfig } from "@/api/wxUtil";
import { uploadImg, getImage } from "@/api/common";
import { formatParams } from "@/utils/params";
import HeCUnImg from "@/assets/images/hecun.png";
import { getImgIds } from "@/utils/index";
import Header from "@/components/header";
import {
  Icon,
  Toast,
  Form,
  Cell,
  CellGroup,
  Field,
  Button,
  Popup,
  Picker,
  Switch,
  Uploader,
  ImagePreview,
  DatetimePicker,
} from "vant";
import FieldLabel from "@/components/fieldLabel";
import { getStorage } from "@/utils/auth";
import BMap from "BMap";
import Vue from "vue";
Vue.use(ImagePreview);
import wx from "weixin-js-sdk";
export default {
  name: "XiansuoHandle",
  components: {
    [Toast.name]: Toast,
    [Icon.name]: Icon,
    [Form.name]: Form,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [DatetimePicker.name]: DatetimePicker,
    [Switch.name]: Switch,
    [Uploader.name]: Uploader,
    [ImagePreview.Component.name]: ImagePreview.Component,
    FieldLabel,
    Header,
  },
  data() {
    return {
      loading: false,
      hecun: HeCUnImg,
      imgshow: false,
      lat: 0,
      lng: 0,
      distance:0,
      form: {
        orderId: "", //工单id
        orderName: "", //工单名字
        disposeId: getStorage("uid"), //处理人id 当前登录人
        disposeName: getStorage("name"), //处理人名字 当前登录人
        disposeResult: "", //处理结果
        disposeTime: "", //处理时间
        address: "", //设备/站点id
        files: [],
        afterFiles: [],
      },
      datetime: new Date(),
      piclist: [],
      imglist: [],
      afterimglist: [],
      recordList: [],
      deviceList: [],
      showTimePicker: false,
      showDevicePicker: false,
      pointAttendance: 0,
    };
  },
  computed: {},
  created() {
    this.parseTime = this.utils.parseTime;
    const id = this.$route.query.id;
    if (isNaN(id)) {
      return Toast.fail("参数错误！");
    }
    this.id = id;
    this.form.disposeTime = this.parseTime(new Date());
    this.getDeviceList();

    this.getGongdanRecordList();
    this.initMap();
  },
  mounted() {
    this.loadConfig();
    this.getGongdanDetail();
    console.log(this.lng);
  },
  updated() {},
  watch: {
       lng: {
	        handler (val, olVal) {
            var that=this
	          var map = new BMap.Map("allmap");
      var point = new BMap.Point(116.404, 39.915);
      var pointAttendance;
      var lngs = that.lng;
      // alert('lngs是'+lngs)
      console.log("lng", lngs);
      var lats = that.lat;

      pointAttendance = new BMap.Point(lngs, lats); //站点位置
      new BMap.Geolocation().getCurrentPosition(
        function (r) {
          if (this.getStatus() == BMAP_STATUS_SUCCESS) {
            //通过Geolocation类的getStatus()可以判断是否成功定位。
            console.log('r是',r);
            point = new BMap.Point(r.point.lng, r.point.lat); // 获取自己当前位置经纬度
            pointAttendance = new BMap.Point(lngs, lats); //站点位置
            // alert('我是'+JSON.stringify(pointAttendance))
            // this.pointAttendance = pointAttendance;
            // console.log(this.pointAttendance);
          }
        },

        { enableHighAccuracy: true }
      );
      console.log("pointAttendance是", pointAttendance);
       const a=new BMap.Point(120,32);
             const b=new BMap.Point(118.6,28.7);
             const c=(map.getDistance(a,b).toFixed(2))
            //  alert('c是'+c)
      //

       setTimeout(function () {

        console.log("pointAttendance是", pointAttendance);
            console.log("point是",point);
            // alert("point是"+JSON.stringify(point));
            // alert("pointAttendance是"+JSON.stringify(pointAttendance));
                 let distance = (map.getDistance(pointAttendance,point).toFixed(2)); //获取本人与站点之间的距离
           console.log('distance',distance);
          //  alert('距离是'+distance)
         that.changedistance(distance)
              }, 6000);

	        },
	        deep: true
	      },

  },
  methods: {
    initMap() {
      //定义获取城市方法
      const geolocation = new BMap.Geolocation();
      var _this = this;
      geolocation.getCurrentPosition(
        function getinfo(position) {
          let address = position.address; //获取城市信息
          console.log(position);
          _this.form.address = `${
            address.province +
            address.city +
            address.district +
            address.street +
            address.street_number
          }`;
        },
        function (e) {
          console.log(e);
          Toast.fail({
            message: "定位失败！",
            forbidClick: true,
          });
        },
        { provider: "baidu" }
      );
    },
    getDeviceList() {
      const params = formatParams({ "Q^NAME_^SL": "" }, { page: 1 });
      getDeviceList(params)
        .then((response) => {
          this.deviceList = response.data.dataResult;
        })
        .catch((err) => {
          Toast.fail(err);
        });
    },
    selectDevice(value) {
      console.log(value);
      this.form.deviceName = value.name;
      this.form.mainId = value.id;
      this.showDevicePicker = false;
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      } else if (type === "hour") {
        return `${val}时`;
      } else if (type === "minute") {
        return `${val}分`;
      }
      return val;
    },
    onTimeConfirm(e) {
      this.form.disposeTime = this.parseTime(e);
      this.showTimePicker = false;
    },
    async getGongdanDetail() {
      Toast.loading("加载中...");
      const id = this.id;
      var that = this;
      let res = await getGongdanDetail({ id: id });
      console.log(res);
      that.lat = res.data.lat;
      console.log("lat", this.lat);
      that.lng = res.data.lng;
      this.form.orderId = res.data.id;
      this.form.orderName = res.data.orderName;
      if (
        res.data.acceptFiles &&
        Object.keys(res.data.acceptFiles).length !== 0
      ) {
        const fileList = JSON.parse(res.data.acceptFiles);
        this.piclist = fileList.map((item) => {
          return process.env.VUE_APP_BASE_API + item.id;
        });
      }
      Toast.clear();
    },
    getGongdanRecordList() {
      const id = this.id;
      getGongdanRecordList({ id: id })
        .then((res) => {
          this.recordList = res.data;
          Toast.clear();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async upload(file) {
      // 这时候我们创建一个formData对象实例
      let fileList = [];
      if (!Array.isArray(file)) {
        fileList.push(file);
      } else {
        fileList = file;
      }
      let that = this;
      fileList.forEach(async (file) => {
        // 通过append方法添加需要的file
        // 这里需要注意 append(key, value)来添加数据，如果指定的key不存在则会新增一条数据，如果key存在，则添加到数据的末尾
        const formData = new FormData();
        formData.append("file", file.file);
        // 调用uploadImage上传的接口
        const res = await uploadImage(formData);
        //[{"fileName":"20.0诉讼百科_03.png","filePath":"file\\2022\\07\\bdf10420042a11ed9f6d1e4946b92c8f.png","id":"997573742743781376"}]
        if (res.success === true) {
          that.form.files.push({
            fileName: res.data.fileName,
            id: res.data.id,
          });
        } else {
          Toast.fail({
            message: res.message,
            forbidClick: true,
          });
        }
      });
    },
    //图片预览
    showImagePreview(piclist, index) {
      ImagePreview({
        images: piclist,
        startPosition: index,
      });
    },
    //修改distance的方法
    changedistance(_param){
      this.distance=_param
    },
    onSubmit() {
      //小于100调用提交接口不然不让提交
      // alert(this.distance)
      if (this.distance <= 500) {
// alert('提交成功')
        const _param = this.form;
        var that = this;
        console.log("submit", _param);
        that.loading = true;
        _param.files = _param.files ? JSON.stringify(_param.files) : "";
        _param.afterFiles = _param.afterFiles
          ? JSON.stringify(_param.afterFiles)
          : "";
        delete _param.deviceName;
        console.log("submit", _param);
        saveGongdanRecord(_param)
          .then(() => {
            that.loading = false;
            Toast.success({
              message: "提交成功！",
              forbidClick: true,
            }),
              setTimeout(function () {
                that.$router.push({
                  path: "/gongdan/detail",
                  query: { id: _param.orderId, isformh: "true" },
                });
              }, 1500);
          })
          .catch((res) => {
            this.loading = false;
            console.log(res);
          });
      } else {
        Toast.fail("不在提交范围内");
      }
      // }, 2000);
    },
    //获取config配置参数的方法
    loadConfig() {
      let url = location.href.split("#")[0];
      let params = {
        url: url,
      };
      getConfig(params)
        .then((response) => {
          let res = response.data;
          console.log("配置参数", res);
          wx.config({
            debug: false,
            appId: res.appid, // 必填,公众号的唯一标识
            timestamp: res.timestamp, // 必填,生成签名的时间戳
            nonceStr: res.nonceStr, // 必填,生成签名的随机串
            signature: res.signature, // 必填,签名
            jsApiList: [
              "chooseImage",
              "previewImage",
              "uploadImage",
              "downloadImage",
            ], // 必填,需要使用的JS接口列表
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    //拍照的接口
    takePhoto(type) {
      let _this = this;
      wx.ready(function () {});
      wx.chooseImage({
        count: 1, // 默认9
        sizeType: ["compressed"], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ["camera"], // 可以指定来源是相册还是相机，默认二者都有
        success: function (res) {
          var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片

          let length = res.localIds.length;
          if (length == 0) {
            Toast.fail("请选择图片");
            return false;
          }
          // _this.getImgData(localIds[0],type);
          _this.uploadImage(localIds, type);
        },
        fail: function (res) {
          // alert('走了fail,没走success')
          // alert(JSON.stringify(res))
        },
      });
    },
    //预览的接口
    previewImage(localId) {
      wx.ready(function () {});
      wx.previewImage({
        current: localId, // 当前显示图片的http链接
        urls: [localId], // 需要预览的图片http链接列表
      });
    },
    //上传到服务器
    uploadImage(localIds, type) {
      let _this = this;
      var localId = localIds.pop();
      var oldform = this.form;
      if (!_this.normFiles1) {
        _this.normFiles1 = [];
      }
      wx.uploadImage({
        localId: localId.toString(),
        isShowProgressTips: 1, // 默认为1，显示进度提示
        success: function (res) {
          var serverId = res.serverId;
          uploadImg({
            imgserverId: serverId,
          })
            .then((response) => {
              let res = response;
              let fileName = res.data.fileName+"."+res.data.ext;
              if (type == 1) {
                _this.form.files.push({
                  fileName: fileName,
                  id: res.data.id,
                });
                _this.imglist.push(localId);
              } else {
                _this.form.afterFiles.push({
                  fileName: fileName,
                  id: res.data.id,
                });
                _this.afterimglist.push(localId);
              }
            })
            .catch((error) => {
              // alert(error)
              Toast.fail({
                message: res.message,
                forbidClick: true,
              });
            });
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.photo {
  width: 100vw;
  overflow: hidden;

  .gray {
    width: 2rem;
    height: 2rem;
    background-color: #f7f8fa;
    display: flex;
    justify-content: center;
    align-items: center;
    .picture {
      font-size: 1rem;
      color: #dcdee0 !important;
    }
  }
  img {
    width: 2rem;
    height: 2rem;
    float: left;
  }
}
.form /deep/ {
  padding: 20px 0px;
  .btn {
    width: 175px;
    margin: 20px 100px;
  }
  .van-field__label {
    flex: none;
    width: 100px;
  }
}
</style>
